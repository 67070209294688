
// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;
	margin-bottom: 3rem;
	padding-top: rem(140px);

	@include breakpoint(giant) {
		padding-top: rem(200px);
	}

	@include breakpoint(full) {
		padding-top: rem(166px);	
	}

	body:not(.index) & {
		@include breakpoint(giant) {
			margin-top: rem(80px);
		}

		@include breakpoint(full) {
			margin-top: rem(120px);
		}
	}

	>.row {
		@include breakpoint(giant) {
			margin-top: rem(60px);
			align-items: center;
		}

		@include breakpoint(huge) {	
			align-items: flex-end;
		}

		.col {
			position: relative;

			.claimContainer {
				margin-bottom: rem(60px);

				@include breakpoint(giant) {
					margin-bottom: rem(80px);
				}

				@include breakpoint(huge) {
					margin-bottom: rem(150px);
				}

				blockquote {
					font-size: rem(38px);
					line-height: 1.1em;
					margin-bottom: 1rem;	
					text-transform: uppercase;

					@include breakpoint(giant) {
						font-size: rem(60px);
						line-height: rem(68px);
					}

					span {
						display: block;
						font-weight: 600;
					}
				}

				p {
					margin-bottom: 0;
				}

				ul {
					position: relative;
					display: block;
					margin-top: 2rem;

					li {
						display: inline-block;
						
						&:first-of-type {
							margin-right: .5rem;	
						}

						.email, .phone {
							display: block;

							@include breakpoint(huge) {
								display: inline-block;
							}
						}

						.email {
							@extend .btn;
							font-size: rem(16px);

							@include breakpoint(tiny) {
								font-size: rem(18px);
							}

							&:before {
								@extend .icon;
								@extend .icon-envelope;
								position: relative;
								display: inline-block;
								margin-right: 1rem;	
							}
						}

						.phone {
							position: relative;
							display: block;
							padding: rem(16px) rem(28px);
							background: $dark;
							color: $light;
							font-size: rem(16px);
							margin-bottom: .5rem;

							@include breakpoint(small) {
								margin-bottom: 0;
							}

							@include breakpoint(large) {
								margin-bottom: .5rem;
							}

							@include breakpoint(full) {
								margin-bottom: 0;
							}
							
							@include breakpoint(tiny) {
								padding: rem(20px) rem(35px);
								font-size: rem(18px);
							}

							&:before {
								@extend .icon;
								@extend .icon-phone;
								position: relative;
								display: inline-block;
								margin-right: 1rem;	
							}
						}
					}
				}
			}

			.swiperButtons {
				position: relative;
				display: flex;
				align-items: center;
				margin-bottom: rem(20px);
				
				&:before {
					content: '';
					position: relative;
					display: inline-block;
					width: 40%;
					height: 1px;
					background: $dark;
					margin-right: 2rem;		

					@include breakpoint(tiny) {
						width: 50%;
					}
				}

				.swiper-button-prev, .swiper-button-next {
					position: relative;
					top: auto;
					left: auto;
					right: auto;
					display: flex;
					align-items: center;
					margin-top: 0;

					&:after {	
						font-size: 28px;
						color: $dark;
					}
				}

				.swiper-button-prev {
					&:after {
						content: '\f137';
						font-family: $iconFont;
					}
				}

				.swiper-button-next {
					&:after {
						content: '\f138';
						font-family: $iconFont;
					}
				}

				.swiper-pagination {
					position: relative;
					display: inline-block;
					width: auto;
					bottom: auto;
					margin: 0 1rem;
				}
			}

			.headerSwiper {
				.swiper-wrapper {
					.swiper-slide {
						@extend %lazyloading;
						padding: rem(160px) 0;

						@include breakpoint(tiny) {
							padding: rem(200px) 0;
						}

						@include breakpoint(large) {
							padding: rem(240px) 0;
						}

						@include breakpoint(giant) {
							padding: rem(280px) 0;
						}

						@include breakpoint(huge) {
							padding: rem(320px) 0;
						}

						&.busreisen1 {
							&.lazyLoaded {
								background: url(/images/layout/tiny-busreisen.jpg) no-repeat center/cover;

								@include breakpoint(tiny) {
									background: url(/images/layout/busreisen.jpg) no-repeat center/cover;
								}
							}
						}

						&.busreisen2 {
							&.lazyLoaded {
								background: url(/images/layout/tiny-busreisen2.jpg) no-repeat center/cover;

								@include breakpoint(tiny) {
									background: url(/images/layout/busreisen2.jpg) no-repeat center/cover;
								}
							}
						}

						&.busreisen3 {
							&.lazyLoaded {
								background: url(/images/layout/tiny-busreisen3.jpg) no-repeat center/cover;

								@include breakpoint(tiny) {
									background: url(/images/layout/busreisen3.jpg) no-repeat center/cover;
								}
							}
						}
					}
				}
			}
		}

		&.angebotsanfrage {
			margin-top: rem(60px);
			align-items: center;
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
	}

	.video {
		@include spaceBoth;
		background: url(/images/layout/small-bgVideo.jpg) no-repeat center/cover;

		@include breakpoint(giant) {
			background: url(/images/layout/giant-bgVideo.jpg) no-repeat center/cover;
		}

		@include breakpoint(full) {
			background: url(/images/layout/bgVideo.jpg) no-repeat center/cover;
		}

		.row {
			.col {
				&:first-of-type {
					.videoContainer {
						@include breakpoint(large, max) {	
							margin-bottom: rem(30px);
						}
					}		
				}

				&:nth-of-type(2) {
					@include breakpoint(small, max) {
						margin-bottom: rem(30px);
					}

					a.modalImage {
						position: relative;
						display: block;
						height: auto;
						max-height: 429px;
						width: 100%;

						img {
							position: relative;
							display: block;
							margin-bottom: 0;
							// height: 100%;
							// width: auto;

							@include breakpoint(giant) {
								height: 100%;
								width: auto;
							}
						}
					}
				}

				&:last-of-type {
					display: flex;
					flex-direction: column;
					justify-content: center;

					dl {
						dt {
							font-size: rem(24px);
							font-weight: 600;
							display: block;
							
							&:before {
								position: absolute;
								top: 3px;
								left: 0;
							}

							&:first-of-type {
								&:before {
									@extend .icon;
									@extend .icon-phone;		
								}	
							}

							&:last-of-type {
								&:before {
									@extend .icon;
									@extend .icon-clock;	
								}
							}
						}

						dd {
							display: block;

							&:first-of-type {
								margin-bottom: 1rem;
							}
						}

						dt, dd {
							position: relative;
							padding-left: 35px;
							color: $light;
						}
					}

					.btn {
						max-width: 250px;

						&:hover {
							background: $light;
							color: $dark;
						}
					}
				}
			}
		}
	}

	.firstContent {
		@include spaceTop;
	}

	.teaser {
		@include spaceBoth;

		.col {
			position: relative;
			background: $dark;
			padding: 0;
			padding-bottom: rem(40px);
			margin: 0 rem($baseGap);
			max-width: calc(100% - #{rem($baseGap*2)});
			flex-basis: calc(100% - #{rem($baseGap*2)});

			@include breakpoint(medium, max) {
				margin-bottom: rem(30px);
			}

			@include breakpoint(medium) {
				max-width: calc(33.33333% - #{rem($baseGap*2)});
				flex-basis: calc(33.33333% - #{rem($baseGap*2)});
			}

			img {
				margin-bottom: rem(40px);
			}

			p {
				color: $light;
				padding: 0 rem(40px);

				&.teaserTitle {
					font-weight: 600;
					font-size: rem(24px);
				}

				&:not(.teaserTitle) {
					margin-bottom: rem(40px);
				}
			}

			.arrow {
				position: absolute;
				bottom: rem(40px);
				left: 50%;
				transform: translate(-50%, 0);
				width: 100%;
				display: flex;
				align-items: center;
				flex-grow: 1;
				padding: 0 rem(40px);

				&:before {
					@extend .icon;
					@extend .icon-arrow-right;	
					font-size: rem(28px);
					position: relative;
					display: inline-block;
					order: 2;
					margin-left: rem(15px);
					color: $light;
					transition: 300ms all;
				}

				&:after {
					content: '';
					position: relative;
					display: inline-block;
					width: 100%;
					height: 1px;
					background: $light;
					order: 1;
				}
			}

			&:hover {
				box-shadow: inset 0px 0px 50px 1px rgba(0,0,0,0.6);

				.arrow {
					&:before {
						color: $primary;	
					}
				}	
			}
		}
	}

	.highlights {
		background: $dark;
		padding: rem(80px) 0;
		margin-top: rem(30px);
		
		.row {
			.col {
				p, li {
					color: $light;
				}

				p {
					margin-bottom: 0;
				}

				&:first-of-type {
					ul {
						@include breakpoint(large, max) {
							margin-bottom: 0;
						}
					}	
				}

				&:nth-of-type(2) {
					ul {
						@include breakpoint(large) {
							margin-bottom: 0;	
						}
					}
				}
			}
		}
	}

	.secondContent {
		@include spaceTop;

		img {
			margin-bottom: 0;
		}
	}

	.col.member {
		padding: rem(30px);
		border: 1px solid $dark;

		@include breakpoint(medium) {
			margin: 0 rem($baseGap);
			margin-bottom: rem(30px);
			max-width: calc(50% - #{rem($baseGap*2)});
			flex-basis: calc(50% - #{rem($baseGap*2)});
		}

		@include breakpoint(giant) {
			max-width: calc(33.33333% - #{rem($baseGap*2)});
			flex-basis: calc(33.33333% - #{rem($baseGap*2)});
		}

		.name {
			position: relative;
			display: block;
			margin-top: rem(24px);
			font-size: rem(24px);
			font-weight: 600;
		}

		.job {
			position: relative;
			display: block;
			margin-bottom: rem(24px);
		}

		.description {
			margin-bottom: 0;
		}
	}

	.flotte {
		&:nth-of-type(odd) {
			@extend .colPaddingRight;
		}

		&:nth-of-type(even) {
			@extend .colPaddingLeft;
		}
	}

	.fahrrad {
		background: $primary;
		color: $light;
		padding: 30px rem($baseGap);

		@include breakpoint(giant) {
			padding: rem(60px) rem($baseGap*2);
		}

		.btn.light {
			background: $light;
			color: $primary;

			&:first-of-type {
				margin-right: .5rem;

				@include breakpoint(full, max) {
					margin-bottom: .5rem;
				}		
			}

			&:hover {
				background: $dark;
				color: $light;
			}
		}
	}

	.subPageVideo {
		max-width: 1000px;
		text-align: center;
	}
}
//Buchungsmodul

.container-min-vh {
	min-height: 600px !important;
}

// FOOTER
//////////////////////////////

#footer {
	margin-top: rem(60px);

    @include breakpoint(medium) {
        margin-top: rem(80px);
    }

    @include breakpoint(large) {
        margin-top: rem(100px);
    }

    @include breakpoint(giant) {
        margin-top: rem(120px);
    }

    @include breakpoint(full) {
        margin-top: rem(160px);
    }

	iframe {
		position: relative;
		display: block;
		height: 500px;
	}

	>.row {
		&.angebotsanfrage {
			margin-bottom: rem(60px);
		}
	}
}

.row.angebotsanfrage {
	align-items: center;
	padding: rem($baseGap*2);
	
	@include breakpoint(small) {
		padding: rem(60px);
	}

	@include breakpoint(large) {
		padding: rem(40px);
	}

	@include breakpoint(giant) {
		padding: rem(60px);
	}

	&:before {
		content: '';
		position: absolute;
		display: block;
		width: calc(100% - #{rem($baseGap*2)});
		height: 100%;
		border: 1px solid $dark;
		top: 0;
		left: 50%;
		transform: translate(-50%, 0);	
	}

	>.col {
		padding: 0;

		@include breakpoint(tiny) {
			padding: 0 rem($baseGap);
		}

		@include breakpoint(small) {
			padding: 0 rem(30px);
		}

		p {
			font-weight: 600;
			font-size: rem(30px);
			line-height: rem(28px);

			@include breakpoint(tiny) {
				font-size: rem(40px);
				line-height: rem(38px);
			}

			@include breakpoint(large) {
				font-size: rem(30px);
				line-height: rem(28px);
			}

			@include breakpoint(giant, max) {
				margin-bottom: 1.5rem;
			}

			@include breakpoint(giant) {
				font-size: rem(40px);
				line-height: rem(38px);
			}

			span {
				display: block;
				font-size: rem(20px);
				font-weight: 300;

				@include breakpoint(tiny) {
					font-size: rem(30px);
				}

				@include breakpoint(large) {
					font-size: rem(20px);
				}

				@include breakpoint(giant) {
					font-size: rem(30px);
				}
			}
		}

		form {
			width: 100%;

			@include breakpoint(huge) {
				display: flex;
				align-items: flex-end;	
			}
			
			.col {
				@include breakpoint(large, max) {
					padding: 0;
				}

				label {
					display: none;
				}

				input {
					&:last-of-type {
						@include breakpoint(huge) {
							margin-bottom: 0;		
						}
					}
				}
			}

			.btn {
				margin-bottom: 0;
				font-weight: 300;
			}
		}
	}
}