// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: giant; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
}
%buttonHover {
	color: $primary;
	// Link Hover
	&:before {
		opacity: 1;
		visibility: visible;	
	}
}
%buttonActive {
	color: $primary;
	// Link Active
	&:before {
		opacity: 1;
		visibility: visible;	
	}
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: '+';
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $primary; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;
	transition: 300ms all;
	min-width: 70%;

	.branding {
		display: none;
	}

	nav {
		width: 100%;

		@include ul {
			&.navi {
				li {
					@include breakpoint($break, max) {
						&:not(:last-of-type) {
							border-bottom: 1px solid $dark;
						}
					}

					@include navButtons {
						@include navButton;
						@include breakpoint(tiny) {
							padding: 1rem 2rem;
						}

						&.active {
							color: $primary;
						}
					}

					@include hasSub {
						@include sub {
							background: $navBg;
							transition: 300ms all;

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
						color: $dark;
					}

					&:hover, &.active {
						>a, >span {
							color: $primary;
						}
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		transition: none;
		background-color: $primary;

		.row {
			justify-content: flex-end;
			align-items: center;
			padding: rem(50px) 0 rem(50px) 0;
			transition: 300ms all; 
		
			.branding {
				position: absolute;
				display: inline-block;
				left: rem($baseGap);
				top: 0;
				transition: 300ms all;
				width: 100%;
				max-width: 150px;
			}

			nav {
				width: auto;

				ul {
					&.navi {
						li {
							a, span {
								position: relative;
								font-size: rem(16px);
								color: $light;
								padding: 1rem;

								@include breakpoint(huge) {
									font-size: rem($baseFontSize);
								}

								&:before {
									content: '';
									position: absolute;
									display: block;
									width: calc(100% - 2em);
									height: 2px;
									background: $light;	
									bottom: 1rem;
									left: 1em;
									opacity: 0;
									visibility: hidden;
									transition: 300ms all;
								}

								&.active {
									color: $light;
								}
							}

							&:hover, &.active {
								>a, >span {
									color: $light;
									
									&:before {
										opacity: 1;
										visibility: visible;	
									}
								}
							}

							&.hasSub {
								.sub {
									background: $primary;
									padding: 2rem;
								}
							}

							&.navBack {
								color: $light;
								opacity: 1;
								transition: 300ms all; 

								&:hover {
									color: $dark;
								}
							}
						}
					}
				}
			}
		}

		&.small {
			.row {
				padding: rem(15px) 0;
			}

			.branding {
				max-width: 100px;
			}

			nav {
				ul {
					&.navi {
						li {
							a, span {
								font-size: rem(16px);
							}
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	}

	.branding {
		position: absolute;
		display: block;
		top: 0;
		left: rem($baseGap);
		max-width: 70px;
		width: 100%;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAddBar {
	background: $primary;
	position: relative;
	display: block;
	padding: rem(30px) 0;
	
	@include breakpoint(large, max) {
		padding-bottom: rem(90px);
	}

	.naviAdd {
		display: flex;
		justify-content: center;

		@include breakpoint(small, max) {
			flex-direction: column;
		}

		li {
			@include breakpoint(small, max) {
				margin-bottom: 1rem;
				text-align: center;
			}

			@include breakpoint(small) {
				margin-right: 2rem;
			}

			&:last-of-type {
				margin-right: 0; 
				margin-bottom: 0;	
			}

			a {
				color: $light;
			}

			&:hover {
				a {
					color: $dark;
				}
			}
		}
	}
}