* {
  margin: 0;
  padding: 0;

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in _config.scss
html {
  background: $light;
  box-sizing: border-box;
  color: $dark;
  font-family: $mainFont;
  font-size: 100% * ($baseFontSize / 16px);
  hyphens: manual;
  line-height: rem($baseLineHeight);

  /* disable text resize in landscape. e.g. on iphone */
  text-size-adjust: none;
  font-weight: 300;
}

body {
  line-height: rem($baseLineHeight);
  opacity: 1 !important;
}

iframe,
[data-iframe] {
  border: none;
  width: 100%;
}

address {
  font-style:normal;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @extend .marginBottom;
  display: block;
  text-rendering: optimizeLegibility;
  hyphens: auto;

  /* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/
}

h1, 
.h1 {
  font-family: $displayFont;
  font-weight: 900; 
  font-size: rem(20px);
  line-height: rem(28px);
  margin-bottom: rem(26px);

  @include breakpoint(tiny) {
    font-size: rem(22px);
    line-height: rem(30px);
  }

  @include breakpoint(small) {
    font-size: rem(24px);
    line-height: rem(32px);
  }

  @include breakpoint(medium) {
    font-size: rem(26px);
    line-height: rem(34px);
  }

  @include breakpoint(giant) {
    font-size: $h1Size;
    line-height: rem(40px);
    font-weight: 800;
  }

  span {
    text-transform: none;
    font-weight: 300;
    display: block;
    font-size: rem(16px);
    line-height: rem(24px);
    padding-bottom: .3rem;

    @include breakpoint(tiny) {
      font-size: rem(18px);
      line-height: rem(26px);
    }

    @include breakpoint(small) {
      font-size: rem(20px);
      line-height: rem(28px);
    }

    @include breakpoint(medium) {
      font-size: rem(22px);
      line-height: rem(30px);
    }

    @include breakpoint(giant) {
      font-size: rem(24px);
      line-height: rem(32px);
      padding-bottom: 0;
    }
  }
}

h2, 
.h2 {
  font-family: $displayFont;
  font-weight: 900; 
  font-size: rem(19px);
  line-height: rem(27px);
  margin-bottom: rem(26px);

  @include breakpoint(tiny) {
    font-size: rem(21px);
    line-height: rem(29px);
  }

  @include breakpoint(small) {
    font-size: rem(24px);
    line-height: rem(32px);
  }

  @include breakpoint(medium) {
    font-size: rem(27px);
    line-height: rem(35px);
  }

  @include breakpoint(giant) {
    font-size: $h2Size;
    line-height: rem(36px);
    font-weight: 800;
  }

  span {
    text-transform: none;
    font-weight: 300;
    display: block;
    font-size: rem(16px);
    line-height: rem(24px);
    padding-bottom: .3rem;

    @include breakpoint(tiny) {
      font-size: rem(18px);
      line-height: rem(26px);
    }

    @include breakpoint(small) {
      font-size: rem(20px);
      line-height: rem(28px);
    }

    @include breakpoint(medium) {
      font-size: rem(22px);
      line-height: rem(32px);
    }
  }
}

h3, 
.h3 {
  font-family: $displayFont;
  font-weight: 900; 
  font-size: rem(19px);
  line-height: rem(27px);
  margin-bottom: rem(26px);

  @include breakpoint(tiny) {
    font-size: rem(21px);
    line-height: rem(29px);
  }

  @include breakpoint(small) {
    font-size: rem(24px);
    line-height: rem(32px);
  }

  @include breakpoint(medium) {
    font-size: rem(27px);
    line-height: rem(35px);
  }

  @include breakpoint(giant) {
    font-size: $h2Size;
    line-height: rem(36px);
    font-weight: 800;
  }

  span {
    text-transform: none;
    font-weight: 300;
    display: block;
    font-size: rem(16px);
    line-height: rem(24px);
    padding-bottom: .3rem;

    @include breakpoint(tiny) {
      font-size: rem(18px);
      line-height: rem(26px);
    }

    @include breakpoint(small) {
      font-size: rem(20px);
      line-height: rem(28px);
    }

    @include breakpoint(medium) {
      font-size: rem(22px);
      line-height: rem(32px);
    }
  }
}

h4,
.h4 {
  font-size: rem($h4Size);
}

h5,
.h5 {
  font-size: rem($h5Size);
}

h6,
.h6 {
  font-size: rem($h6Size);
}

/**
 * Links
 */
a {
  color: $primary;
  word-wrap: break-word;
  transition: 300ms all;
  text-decoration: none;

  &:focus,
  &:hover,
  &:active {
    color: lighten($dark, 20%);
  }

  img {
    border: none;
  }

  &[data-rel="lightbox:"] {
    margin-bottom: 1rem;
    display: block;
  }

  &[href^="tel"] {
    color: inherit;
    text-decoration: underline;
  }
}

a:not(.btn):focus,
[tabindex]:focus {
  outline: $outline;
  outline-offset: rem(5px);
}

hr,
.hr {
  display: flex;
  background: $medium;
  border: none;
  clear: both;
  height: rem(1px);
  margin: 1rem 0;
  flex-basis: 100%;
}

ul,
ol {
  list-style: none;

  &.styledList {
    li {
      padding-left: rem(25px);
      margin-bottom: rem(7px);

      &:before {
        @extend .icon;
        @extend .icon-arrow-right;
        text-indent: rem(-25px);
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}

dl {
  &.styledList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      background: rgba($medium, 0.8);
      padding: rem(15px);
    }

    dt {
      padding-bottom: 0;
      font-weight: bold;
    }

    dd {
      padding-top: 0;

      &:not(:last-child) {
        margin-bottom: rem(10px);
      }
    }
  }

  &.floatList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      vertical-align: top;
      float: left;
    }

    dt {
      padding-right: 0.5rem;
      width: 40%;
      clear: left;
    }

    dd {
      width: 60%;
    }
  }
}

ol {
  &.styledList
  {
    counter-reset: item;
  
    li {
      counter-increment: item;
  
      &:before {
        content: counters(item, ".") ". ";
      }
    }
  
    ol {
      margin-bottom: 0;
  
      li {
        &:before {
          content: counters(item, ".") " ";
          margin-right: rem(5px);
        }
      }
    }
  }
}

/*
 * responsive images
 */

img {
  vertical-align: bottom;
  float: none;
  height: auto;
  max-width: 100%;
  width: 100%;

  &[src^='http://cms.'], &[src^='https://cms.'] {
    max-width: none;
    width: auto;
  }

  [data-rel] &,
  .lightbox-image & {
    margin-bottom: 0;
  }

  &[data-src] {
    @extend %lazyloading;
  }
}

/**
 * Tables
 */
main {
  table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-size: rem($baseFontSize);

    @include breakpoint(small, max) {
      overflow-x: auto;
      display: block;
    }

    caption {
      text-align: left;
      padding: rem($baseGap) rem($baseGap) rem(25px);
      display: table-caption;
      font-weight: bold;
      font-size: 1.2rem;
    }

    thead {
      border-collapse: collapse;
      display: table-header-group;
    }

    tbody {
      width: 100%;
      overflow-x: scroll;
      display: table-row-group;

      tr {
        padding: rem($baseGap) 0;

        &:not(:last-child) {
          border-bottom: rem(1px) solid $medium;
        }
      }
    }

    tr {
      display: table-row;
    }

    th,
    td {
      padding: rem($baseGap);
      display: table-cell;
      vertical-align: top;
    }

    @include breakpoint(small, max) {
      td {
        min-width: 50vw;
      }
    }
  }
}
