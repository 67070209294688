// Use this file for your custom mixins/functions


@mixin spaceTop {
    padding-top: rem(60px);

    @include breakpoint(medium) {
        padding-top: rem(80px);
    }

    @include breakpoint(large) {
        padding-top: rem(100px);
    }

    @include breakpoint(giant) {
        padding-top: rem(120px);
    }

    @include breakpoint(full) {
        padding-top: rem(160px);
    }
}

@mixin spaceBottom {
    padding-bottom: rem(60px);

    @include breakpoint(medium) {
        padding-bottom: rem(80px);
    }

    @include breakpoint(large) {
        padding-bottom: rem(100px);
    }

    @include breakpoint(giant) {
        padding-bottom: rem(120px);
    }

    @include breakpoint(full) {
        padding-bottom: rem(160px);
    }
}

@mixin spaceBoth {
    padding: rem(60px) 0;

    @include breakpoint(medium) {
        padding: rem(80px) 0;
    }

    @include breakpoint(large) {
        padding: rem(100px) 0;
    }

    @include breakpoint(giant) {
        padding: rem(120px) 0;
    }

    @include breakpoint(full) {
        padding: rem(160px) 0;
    }
}